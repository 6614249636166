import { currentWallet } from '@amply-app/wallet';
import { useQuery } from '@tanstack/react-query';
import { RefetchInterval } from '@ui/config/ui';
import { getSDK } from '@ui/utils';
import { Fraction } from 'bi-fraction';
import { flatten, map } from 'lodash-es';

export const useUserReservesIncentivesData = () => {
  const sdk = getSDK();
  const account = currentWallet.useAccount();
  const provider = sdk
    .getContractAddress('PoolAddressesProvider')
    .toLowerCase();

  const preAmplyToken = sdk.getContractAddress('PreAmplyToken').toLowerCase();
  return useQuery(
    ['useUserReservesIncentivesData', account],
    async () => {
      const data = await sdk
        .getUiIncentiveDataProviderV3()
        .getUserReservesIncentivesData(provider, account!);

      // const aTokenIncentivesTokens = flatten(map(data, (d) => d[1][2]));
      // const vTokenIncentivesTokens = flatten(map(data, (d) => d[2][2]));
      // const allIncentivesTokens = [
      //   ...aTokenIncentivesTokens,
      //   ...vTokenIncentivesTokens,
      // ];

      const aTokens = flatten(map(data, (d) => d[1][0]));
      const vTokens = flatten(map(data, (d) => d[2][0]));
      const allAAndVTokens = [...aTokens, ...vTokens];

      const preAmplyTokenAmountBn = await sdk
        .getRewardsController()
        .getUserRewards(allAAndVTokens, account!, preAmplyToken);

      // const rewardsTokenSymbols = uniq(
      //   map(allIncentivesTokens, (token) => token[0]),
      // );

      // const allIncentivesTokensInfo = {
      //   0: 'rewardTokenSymbol',
      //   1: 'rewardTokenAddress',
      //   2: 'userUnclaimedRewards',
      //   3: 'tokenIncentivesUserIndex',
      //   4: 'rewardPriceFeed',
      //  SC team said they remove rewardPriceFeed, and here it is 0. we should get it from h2 finance side.
      //   5: 'priceFeedDecimals',
      //   6: 'rewardTokenDecimals',
      // };
      // const allIncentivesTokensInfo = map(rewardsTokenSymbols, (token) => ({
      //   symbol: token,
      //   address: allIncentivesTokens.find((t) => t[0] === token)?.[1] ?? '',
      //   amount: reduce(
      //     allIncentivesTokens,
      //     (a, c) => a.add(token === c[0] ? c[2] : Fraction.ZERO),
      //     Fraction.ZERO,
      //   ),
      // }));

      return {
        preAmplyTokenAmount: new Fraction(preAmplyTokenAmountBn).shr(18),
        allAAndVTokens,
      };
    },
    { enabled: !!account, refetchInterval: RefetchInterval.Normal },
  );
};
