import { Box, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import type { TooltipProps } from 'recharts';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';

import { dateFormatter } from './date';
import type { APRChartData, APRChartProps } from './types';

const CustomTooltip = ({
  active,
  payload,
  formatter = (value) => `$${value}`,
  labelFormatter = (label) => format(new Date(label), 'dd/MM'),
}: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const currPayload = payload[0].payload as APRChartData[number];
    return (
      <Box bg={'gradient.black_interactive'} p={2} borderRadius={'4px'}>
        <Text color={'primary.white'} textStyle={'caption'}>
          {labelFormatter(currPayload.date, payload)}
        </Text>
        <Text color={'primary.white'} textStyle={'caption_legend'}>
          {formatter(
            currPayload.value,
            '',
            currPayload,
            payload.indexOf(currPayload),
            payload,
          )}
        </Text>
      </Box>
    );
  }
  return null;
};

export const APRChart = ({
  data,
  linearGradientProps,
  chartTitleProps,
  chartContainerProps,
  areaChartProps = {},
  xAxisProps = {},
  areaProps = {},
  tooltipProps = {},
  ...props
}: APRChartProps) => {
  return (
    <Box {...props}>
      {chartTitleProps && (
        <Text
          textStyle={chartTitleProps.style ? chartTitleProps.style : 'h4'}
          color={chartTitleProps.color ? chartTitleProps.color : 'white'}
        >
          {chartTitleProps.text}
        </Text>
      )}
      <ResponsiveContainer
        width={chartContainerProps.width}
        aspect={chartContainerProps.aspect}
        minHeight={chartContainerProps.minHeight}
      >
        <AreaChart
          height={areaChartProps.height ? areaChartProps.height : 133}
          data={data}
          margin={{
            top: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient
              id={linearGradientProps.id}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop
                stopColor={linearGradientProps.stopColor}
                stopOpacity={linearGradientProps.stopOpacity ?? '0.4'}
              />
              <stop
                offset="1"
                stopColor={linearGradientProps.stopColor}
                stopOpacity={'0'}
              />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            tickLine={false}
            axisLine={false}
            tickFormatter={dateFormatter}
            tick={{
              fontSize: 10,
              fontWeight: '600',
              fill: xAxisProps.color ? xAxisProps.color : 'white',
            }}
            interval="preserveStartEnd"
            {...xAxisProps}
          />
          {tooltipProps.hide ? null : (
            <Tooltip
              content={CustomTooltip}
              cursor={{
                strokeDasharray: '2 2',
              }}
              {...tooltipProps}
            />
          )}
          <Area
            type="basis"
            dataKey="value"
            stroke="#FD98E0"
            fill={`url(#${linearGradientProps.id})`}
            activeDot={false}
            {...areaProps}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};
