import { Fraction } from 'bi-fraction';

/**
 * https://docs.aave.com/developers/core-contracts/pool#supply
 * unique code for 3rd party referral program integration. Use 0 for no referral.
 */
export const REFERRAL_CODE = 0;

/**
 * https://github.com/cronos-labs/amply-finance-core/blob/c7966a40662aed8bdf81d1df9f7d0a4b9729eb04/contracts/protocol/libraries/types/DataTypes.sol#L78
 * Stable mode deprecated, use Variable, i.e. 1: enum InterestRateMode {NONE, VARIABLE}
 */
export const INTEREST_RATE_MODE = 1;

export const MOCK_ZKCRO_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';

/**
 * As now don't have AMPLY token in the zkEVM network, so we use the USD price as 1,
 * and have confirmed with SC team that they removed the rewardPriceFeed in getUserReservesIncentivesData function,
 * so we can use this constant, but still need to confirm with PM
 */
export const AMPLY_USD_PRICE = new Fraction('0.05');

export const SECONDS_PER_YEAR = 31536000;

export const SECONDS_PER_DAY = 86400;

export const WEI_DECIMALS = 18;

export const MAX_WITHDRAWAL_HF = new Fraction(1.01);

export const WITHDRAWAL_HF_WARNING_THRESHOLD = new Fraction(1.02);
