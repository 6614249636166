import type { DotProps } from 'recharts';

import { textAnchor, textFill, textOffsetX, textOffsetY } from './constants';
import type { ViewBox } from './types';

export const CustomizedActiveDot = (
  props: DotProps & { value?: number; index?: number },
) => {
  const { cx, cy, value, r, strokeWidth, fill, index, fontSize } = props;
  if (!cx || !cy || !value || !r) return null;

  const textX = index === 0 ? cx + textOffsetX : cx - textOffsetX;
  const textY = cy - textOffsetY - r * 2;

  return (
    <>
      <text
        x={textX}
        y={textY}
        fontSize={fontSize ?? '14px'}
        fill={textFill}
        textAnchor={textAnchor}
      >
        {value}
      </text>
      <circle
        cx={cx}
        cy={cy}
        r={r * 2}
        stroke={'#05121A'}
        strokeWidth={strokeWidth}
        fill={fill}
      />
    </>
  );
};

export const CustomizedReferenceDotLabel = (props: {
  viewBox?: ViewBox;
  value?: number;
  index?: number;
  fontSize?: string;
}) => {
  const { value, viewBox, index, fontSize } = props;
  if (!value || !viewBox) return null;

  const textX =
    index === 0
      ? viewBox.x + viewBox.width / 2 + textOffsetX
      : viewBox.x + viewBox.width / 2 - textOffsetX;
  const textY = viewBox.y - textOffsetY;

  return (
    <text
      x={textX}
      y={textY}
      fontSize={fontSize ?? '14px'}
      fill={textFill}
      textAnchor={textAnchor}
    >
      {value}
    </text>
  );
};
