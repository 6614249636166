import { Box, Button, Center, Flex, Text } from '@chakra-ui/react';
import { TxStatus } from '@ui/commonStateController';
import TokenImage from '@ui/components/Markets/IndividualMarket/TokenImage';
import { InDesktop, InMobile } from '@ui/components/MobileOrDesktop';
import { Num } from '@ui/components/Num';
import { useUserReservesIncentivesData } from '@ui/hooks/data/useUserReservesIncentivesData';
import { Fraction } from 'bi-fraction';
import React from 'react';

import ClaimModal, { useClaimModal } from './ClaimModal';

const Earnings = () => {
  const [txStatus, openModal, setTxStatus] = useClaimModal((s) => [
    s.txStatus,
    s.open,
    s.setTxStatus,
  ]);

  const { data: userReservesIncentivesData } = useUserReservesIncentivesData();

  const totalAmount = userReservesIncentivesData?.preAmplyTokenAmount;

  const handleClaim = () => {
    setTxStatus(TxStatus.Default);
    openModal();
  };

  return (
    <>
      <Flex
        flex={1}
        gap={2}
        flexDir={'column'}
        color={'primary.white'}
        bg={{ desktop: 'rgba(5, 18, 26, 0.3)' }}
        p={{ desktop: 2 }}
        minW={{ desktop: '268px' }}
        backdropFilter={'blur(2px)'}
      >
        <InDesktop>
          <Center textStyle={'body2'} mt={2}>
            My earnings
          </Center>
        </InDesktop>

        <Flex gap={4} alignItems={'center'}>
          <Flex gap={1} flexDir={'column'} flex={1}>
            {/* <Text textStyle={'body3'}>Total claimable amount</Text>
            <Num textStyle={'body1Bold'} value={totalUSDValue} suffix="USD" /> */}
          </Flex>
          <InMobile>
            <Button
              w="120px"
              onClick={handleClaim}
              isLoading={txStatus === TxStatus.Loading}
              isDisabled={totalAmount?.lte(Fraction.ZERO)}
            >
              Claim
            </Button>
          </InMobile>
        </Flex>

        <Flex gap={2} flexDir={'column'}>
          {/* {map(userReservesIncentivesData?.allIncentivesTokensInfo, (d) => (
            <Flex justifyContent={'space-between'} key={d.symbol}>
              <Text textStyle={'body3'}>{d.symbol}</Text>
              <Box>
                <Flex gap={1} mb={0.5}>
                  <Num textStyle={'body1Bold'} value={d.amount} isShowUnit />
                  <TokenImage w="24px" symbol={d.symbol} />
                </Flex>
                {!POST_MVP_FLAG && (
                  <UsdValue
                    textStyle={'body3'}
                    value={d.amount}
                    price={AMPLY_USD_PRICE}
                  />
                )}
              </Box>
            </Flex>
          ))} */}
          {userReservesIncentivesData?.preAmplyTokenAmount.gt(0) && (
            <Flex justifyContent={'space-between'}>
              <Text textStyle={'body3'}>{'preAMP'}</Text>
              <Box>
                <Flex gap={1} mb={0.5}>
                  <Num
                    textStyle={'body1Bold'}
                    value={userReservesIncentivesData.preAmplyTokenAmount}
                    isShowUnit
                  />
                  <TokenImage w="24px" symbol={'preAMP'} />
                </Flex>
              </Box>
            </Flex>
          )}
        </Flex>

        <InDesktop>
          <Button
            onClick={handleClaim}
            isLoading={txStatus === TxStatus.Loading}
            isDisabled={totalAmount?.lte(Fraction.ZERO)}
            mt={6}
          >
            Claim
          </Button>
        </InDesktop>
      </Flex>
      <ClaimModal />
    </>
  );
};

export default Earnings;
