import { chartData } from '@ui/components/AmplyCharts/APR.stories';
import { tokenAddressMap } from '@ui/components/TokenInput/constants';
import type { UseAllPoolsData } from '@ui/hooks/data';
import {
  useIncentiveAPY,
  useSupplyAPY,
  useSupplyCap,
  useTokenPrice,
  useTokenSupply,
  useTokenUtilization,
} from '@ui/hooks/data';
import React, { useMemo } from 'react';

import { Information } from './Information';

interface SupplyPanelProps {
  tokenData: UseAllPoolsData;
}
export const SupplyPanel = ({ tokenData }: SupplyPanelProps) => {
  const { data: tokenSupply } = useTokenSupply(tokenData.aTokenAddress);
  const { data: tokenPrice } = useTokenPrice(tokenData.tokenAddress);
  const { data: supplyAPY } = useSupplyAPY(tokenData.tokenAddress);
  const tokenUtilization = useTokenUtilization(tokenData);
  const isSupplyCap = useSupplyCap(tokenData);
  const { data: incentiveData } = useIncentiveAPY();
  const incentiveSupplyData = useMemo(() => {
    const incentiveTokenIndex = incentiveData?.tokenAddress.findIndex(
      (item) => tokenAddressMap[item.toLowerCase()] === tokenData.symbol,
    );
    return incentiveData?.supplyIncentiveData[incentiveTokenIndex!];
  }, [incentiveData, tokenData]);
  return (
    <Information
      data={{
        symbol: tokenData.symbol,
        tokenAPY: supplyAPY,
        incentiveDetails: incentiveSupplyData,
        chartData,
        tokenAmount: tokenSupply,
        tokenUSDValue: tokenPrice,
        utilization: tokenUtilization,
        isCapReached: isSupplyCap,
      }}
      type="supply"
    />
  );
};
