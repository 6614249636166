import { ChainId } from '../types';
const ADDRESS_BY_CHAIN = {
    [ChainId.CRONOS_ZKEVM_MAINNET]: {
        CronosId: '0x7F4C61116729d5b27E5f180062Fdfbf32E9283E5',
        PoolAddressesProviderRegistry: '0xc8AFD4de7E50f59107a87DB82b5F1Eb2F41D4F0F',
        PreAmplyToken: '0xb58c0f5dc3F1FeE5f9907C5f737b3BEcCd476e61',
        PoolAddressesProvider: '0xDA59Cc2Ee08ce5F830B1190bfed34415772Ddc57',
        AmplyProtocolDataProvider: '0x47656eb2A31094b348EBF458Eccb942d471324eD',
        PoolConfigurator: '0x31D864780Cc862C5F6b7DEfc8627c2593339C59e',
        ReservesSetupHelper: '0x0aCE43a9DA33cA4c3D69D582E70D2Bd84a5a3f19',
        ACLManager: '0x6C4A080556E003Ed0628024dB814A83164E17891',
        AmplyOracle: '0xF43E78E5F1204c5cAB40Dd2825f1bee9451e267e',
        L2Encoder: '0x5F3e99759962018e4c371128D3cA32E8E5FfE66A',
        EmissionManager: '0x6806b39B7A63c4FbED56B4680f86142455Cc552B',
        RewardsController: '0x35F8fddF1d4323423D80FB3B2f3C9847c9924a2b',
        WrappedTokenGatewayV3: '0xC273d6Df2C9b7e337Eb1dDc0067cE778bb8D3955',
        WalletBalanceProvider: '0x2Ce4531CB531B655006D79cc4D3FBc447d2F5913',
        UiIncentiveDataProviderV3: '0x4BEC5cf1408fffcC2C65fe1061fd7abd22d1BEC8',
        UiPoolDataProviderV3: '0xCbD2125264e9F69437845Ea52FB9d23DD31B5fB5',
        wzkCRO: '0xC1bF55EE54E16229d9b369a5502Bfe5fC9F20b6d',
        vUSD: '0x5b91e29Ae5A71d9052620Acb813d5aC25eC7a4A2',
        vETH: '0x271602A97027ee1dd03b1E6e5dB153eB659A80b1',
        wBTC: '0xd65e5dba71231d35a5802ba83dc6cb6746c9758d',
        ETH: '0x898B3560AFFd6D955b1574D87EE09e46669c60eA',
        CRO: '0xBCaA34FF9D5BFD0d948b18Cf6Bf39a882F4a1cBD',
        USDC: '0xaa5b845F8C9c047779bEDf64829601d8B264076c',
        Paymaster: '0xF809566a93CB882E5055A815bEbe57F8aA6ED9F9',
    },
    [ChainId.CRONOS_ZKEVM_TESTNET]: {
        CronosId: '',
        PoolAddressesProviderRegistry: '0x966E82F42EF0477074472978f141DCb1cDE21469',
        PreAmplyToken: '',
        PoolAddressesProvider: '0xE3A24B1303467882085322cf0454c50f593D2241',
        AmplyProtocolDataProvider: '0x0B64b08EC3B01d1bDD492cEe79249F7213a5fE7F',
        PoolConfigurator: '0x65f7FEA27FE6443bdaE76559850658666E8b86e3',
        ReservesSetupHelper: '0xaf8d6F4E9fa4895C1353D2479a794aBc4244526F',
        ACLManager: '0x3e391c0cc82Ab937137AED83c5f1Bab82aEcB5E4',
        AmplyOracle: '0xD199274608Dffe07D1e9fEBf883A94f1CA6B786c',
        L2Encoder: '0x74065C0999a5f03229851946b43A72B8Bb06dDAA',
        EmissionManager: '0xeD38d58383a2aAB275c7E9196DA07C71d2C07a87',
        RewardsController: '0x13300e24d01EF29c81125ed5fe33ac17e67e47ab',
        WrappedTokenGatewayV3: '0x3A5B3da5B92685909FfA4F70C96f13692F92AD5e',
        WalletBalanceProvider: '0x1Da2Cc45CeecF652650E0732b842960bd670fd4A',
        UiIncentiveDataProviderV3: '0x1a89b935b192eed57b25f2346A28B61F877ed64B',
        UiPoolDataProviderV3: '0x7B0b7C2c4723bF598ad06c64B6210F67a28bbcd5',
        wzkCRO: '0xf9Bb37013de8Cd3f89b3623Af9EE1b1B32D872C9',
        vUSD: '0x66c8221E5938A296D7542aB3D7cB856e789C06B2',
        vETH: '0x8eb8f79CaB3A1c02F9A0Fc33A8E792625D6a2405',
        wBTC: '',
        ETH: '',
        CRO: '',
        USDC: '',
        Paymaster: '0xe411C59B34e0Aa7314374CCF9d2CCABB06006d27',
    },
};
export const getContractAddress = (chainId, contractName) => {
    return ADDRESS_BY_CHAIN[chainId][contractName];
};
export const getEnsAddress = (chainId) => {
    switch (chainId) {
        case ChainId.CRONOS_ZKEVM_MAINNET:
        case ChainId.CRONOS_ZKEVM_TESTNET:
            return getContractAddress(chainId, 'CronosId');
        default:
            throw new Error('unreachable');
    }
};
