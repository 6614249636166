import { Button, Center, Link, ModalCloseButton } from '@chakra-ui/react';
import type {
  ModalController,
  TxStatusController,
} from '@ui/commonStateController';
import {
  createModalController,
  createTxStatusController,
  TxStatus,
} from '@ui/commonStateController';
import {
  TransactionErrorContent,
  TransactionLoadingContent,
} from '@ui/components/CommonContents';
import { InDesktop } from '@ui/components/MobileOrDesktop';
import {
  ModalOrDragDrawerContent,
  ModalOrDrawer,
  ModalOrDrawerBody,
  ModalOrDrawerFooter,
  ModalOrDrawerHeader,
  ModalOrDrawerOverlay,
} from '@ui/components/ModalOrDrawer';
import { getExplorerLink } from '@ui/utils/getExplorerLink';
import { create } from 'zustand';

import { DefaultContent } from './DefaultContent';

export const useClaimModal = create<ModalController & TxStatusController>()(
  (...a) => ({
    ...createModalController(...a),
    ...createTxStatusController(...a),
  }),
);

const ClaimModal = () => {
  const [isOpen, txStatus, txHash, txError, closeModal] = useClaimModal((s) => [
    s.isOpen,
    s.txStatus,
    s.txHash,
    s.txError,
    s.close,
  ]);

  return (
    <ModalOrDrawer
      scrollBehavior="inside"
      placement="bottom"
      isOpen={isOpen}
      onClose={closeModal}
    >
      <ModalOrDrawerOverlay />
      <ModalOrDragDrawerContent
        bgColor={'primary.background'}
        backgroundImage={'url(/images/bg-full.svg)'}
        backgroundSize={'cover'}
        roundedTop="10px"
        minH={'60vh'}
        minW={{ desktop: '500px' }}
      >
        <>
          <ModalOrDrawerHeader>Claim</ModalOrDrawerHeader>
          <InDesktop>
            <ModalCloseButton color={'primary.cta'} />
          </InDesktop>
          {txStatus === TxStatus.Default && <DefaultContent />}
          {txStatus === TxStatus.Loading ? <TransactionLoadingContent /> : null}
          {txStatus === TxStatus.Success ? (
            <>
              <ModalOrDrawerBody>
                <Center textStyle={'h3'}>Transaction complete</Center>
                <Center color={'primary.text2'} my={2}>
                  You have claimed
                </Center>
                <Center mt={4}>
                  <Link
                    href={getExplorerLink(txHash ?? '0x000000', 'transaction')}
                    isExternal
                    color="primary.cta"
                    target="_blank"
                    textStyle={'cta'}
                  >
                    View on CronosZK Explorer
                  </Link>
                </Center>
              </ModalOrDrawerBody>
            </>
          ) : null}
          {txStatus === TxStatus.Failed ? (
            <TransactionErrorContent error={txError} />
          ) : null}
          <ModalOrDrawerFooter>
            {txStatus === TxStatus.Success && (
              <Button w={'100%'} onClick={closeModal}>
                Done
              </Button>
            )}
          </ModalOrDrawerFooter>
        </>
      </ModalOrDragDrawerContent>
    </ModalOrDrawer>
  );
};

export default ClaimModal;
