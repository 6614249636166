import { ExpandRight } from '@amply-app/icons';
import { Box, Button, Flex, Icon, Switch, Text } from '@chakra-ui/react';
import { TxStatus } from '@ui/commonStateController';
import { ChakraNextLink } from '@ui/components/ChakraNextLink';
import { Divider } from '@ui/components/MarketCard';
import TokenImage from '@ui/components/Markets/IndividualMarket/TokenImage';
import { Num, Percent } from '@ui/components/Num';
import { UsdValue } from '@ui/components/Num/UsdValue';
import {
  SupplyAndBorrowType,
  useSupplyAndBorrowModal,
} from '@ui/components/SupplyAndBorrowModal';
import { tokenAddressMap } from '@ui/components/TokenInput/constants';
import {
  useWithdrawAndRepayModal,
  WithdrawOrRepay,
} from '@ui/components/WithdrawAndRepayModal';
import type { UseAllPoolsData } from '@ui/hooks/data';
import { useAllPoolsData, useIncentiveAPY, useSupplyAPY } from '@ui/hooks/data';
import { SECONDS_PER_DAY } from '@ui/hooks/data/constants';
import { Fraction } from 'bi-fraction';
import { find } from 'lodash-es';
import React, { Fragment, useMemo } from 'react';

import {
  CollateralDrawerType,
  useCollateralDrawer,
} from '../CollateralDrawer/hooks';
import type { CardData } from '../Overview';
import type { Props } from './SupplyCards';

const HEADS = ['Asset', 'Supplied', 'Enabled as collateral', 'APR'];
const COLUMN_STYLES: {
  width: string;
  textAlign: 'left' | 'center' | 'right';
}[] = [
  { width: '30%', textAlign: 'left' },
  { width: '20%', textAlign: 'left' },
  { width: '30%', textAlign: 'center' },
  { width: '20%', textAlign: 'right' },
];
const SupplyRow = ({ data }: { data: CardData }) => {
  const {
    symbol,
    label,
    aTokenBalance,
    asCollateral,
    tokenAddress,
    tokenUSDPrice,
  } = data;

  const { data: supplyAPY } = useSupplyAPY(tokenAddress);
  const { data: poolsData } = useAllPoolsData();
  const tokenData = find(poolsData, (data) => data.symbol === symbol);
  const { data: incentiveData } = useIncentiveAPY();
  const incentiveSupplyData = useMemo(() => {
    const incentiveTokenIndex = incentiveData?.tokenAddress.findIndex(
      (item) => tokenAddressMap[item.toLowerCase()] === tokenData?.symbol,
    );
    return incentiveData?.supplyIncentiveData[incentiveTokenIndex!];
  }, [incentiveData, tokenData]);

  const [
    openDrawer,
    setAmount,
    setTokenSymbol,
    setTokenAddress,
    setTokenUSDValue,
    setType,
  ] = useCollateralDrawer((s) => [
    s.open,
    s.setAmount,
    s.setTokenSymbol,
    s.setTokenAddress,
    s.setTokenUSDValue,
    s.setType,
  ]);

  const handleWithDrawClick = () => {
    useWithdrawAndRepayModal.setState((s) => ({
      ...s,
      isOpen: true,
      type: WithdrawOrRepay.Withdraw,
      txStatus: TxStatus.Default,
      tokenSymbol: symbol,
      tokenAddress: tokenAddress,
      isCollateral: asCollateral,
      tokenBalance: aTokenBalance,
      tokenUSDPrice,
    }));
  };

  const handleSupplyMoreClick = () => {
    useSupplyAndBorrowModal.setState((s) => ({
      ...s,
      type: SupplyAndBorrowType.Supply,
      supplyTokenData: poolsData?.find(
        (pool: UseAllPoolsData) => pool.symbol === symbol,
      ),
      isOpen: true,
    }));
  };

  const handleSwitchAsCollateral = () => {
    openDrawer();
    setTokenSymbol(symbol);
    setTokenAddress(tokenAddress);
    setAmount(aTokenBalance);
    setTokenUSDValue(aTokenBalance.mul(tokenUSDPrice));
    setType(
      asCollateral ? CollateralDrawerType.Disable : CollateralDrawerType.Enable,
    );
  };
  return (
    <>
      <Flex>
        <Flex {...COLUMN_STYLES[0]} gap={'10px'}>
          <TokenImage symbol={symbol} w={'36px'} h={'36px'} />
          <Box>
            <Text>{label}</Text>
            <Text textStyle={'body3'} color={'primary.text2'}>
              {symbol}
            </Text>
          </Box>
        </Flex>
        <Box {...COLUMN_STYLES[1]}>
          <Num value={aTokenBalance} textStyle={'h4'} />
          <UsdValue
            value={aTokenBalance}
            price={tokenUSDPrice}
            color={'primary.text2'}
          />
        </Box>
        <Box {...COLUMN_STYLES[2]}>
          <Switch
            isChecked={asCollateral}
            onChange={handleSwitchAsCollateral}
          />
        </Box>
        <Box {...COLUMN_STYLES[3]}>
          <Percent
            value={supplyAPY}
            textStyle={'h4'}
            justifyContent={'flex-end'}
          />
          <Percent
            value={supplyAPY}
            textStyle={'body1'}
            justifyContent={'flex-end'}
          />
          {incentiveSupplyData?.map((details) => {
            return (
              <Flex gap={1} key={details[0]} justifyContent={'flex-end'}>
                <Num
                  key={details[0]}
                  justifyContent={'flex-end'}
                  value={new Fraction(details[2])
                    .shr(Number(details[6]))
                    .mul(SECONDS_PER_DAY)}
                  textStyle={'body1'}
                  prefix={<Text> ${details[0]}:</Text>}
                  suffix={
                    <>
                      <Text>/day</Text>
                      <TokenImage w={'24px'} h={'24px'} symbol={details[0]} />
                    </>
                  }
                />
              </Flex>
            );
          })}
        </Box>
      </Flex>
      <Flex justifyContent={'space-between'} mt={4}>
        <Flex px={4} gap={3}>
          <Button
            h={'30px'}
            minW={'95px'}
            variant={'outline'}
            onClick={handleWithDrawClick}
          >
            Withdraw
          </Button>
          <Button
            h={'30px'}
            minW={'160px'}
            variant={'outline'}
            onClick={handleSupplyMoreClick}
          >
            Supply more
          </Button>
        </Flex>
        <ChakraNextLink href={`/markets/token/?symbol=${symbol}`}>
          <Button h={'30px'} minW={'100px'} variant={'link'}>
            Market Details
            <Icon as={ExpandRight} boxSize={7} color={'primary.cta'} />
          </Button>
        </ChakraNextLink>
      </Flex>
    </>
  );
};

const SupplyTable = ({ data }: Props) => {
  return (
    <Box minW={'645px'} maxW={'645px'}>
      <Flex px={4} mb={2}>
        {HEADS.map((head, i) => (
          <Box
            key={head}
            w={COLUMN_STYLES[i].width}
            textAlign={COLUMN_STYLES[i].textAlign}
          >
            {head}
          </Box>
        ))}
      </Flex>
      <Box
        bg={'rgba(5, 18, 26, 0.3)'}
        p={'20px 16px'}
        backdropFilter={'blur(2px)'}
      >
        {data?.length === 0 && (
          <Text color={'primary.white'} textAlign={'center'} p={4}>
            No data
          </Text>
        )}
        {data?.map((item, i) => (
          <Fragment key={item.symbol}>
            <SupplyRow data={item} />
            {i !== data.length - 1 && (
              <Box mt={5}>
                <Divider />
              </Box>
            )}
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default SupplyTable;
