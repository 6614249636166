import { Box, Text } from '@chakra-ui/react';
import { UsdValue } from '@ui/components/Num/UsdValue';
import { useTokenPrice } from '@ui/hooks/data';
import { Fraction } from 'bi-fraction';

import type { TokenInputContextProps } from '../types';

export const InputMessage = ({
  value,
  selectedTokenData,
  errorMsg,
  warningMsg,
}: Pick<
  TokenInputContextProps,
  'value' | 'selectedTokenData' | 'errorMsg' | 'warningMsg'
>) => {
  const { data: tokenPrice } = useTokenPrice(selectedTokenData?.tokenAddress);
  if (!value && !errorMsg && !warningMsg) return <Box mb={4} />;
  return (
    <>
      {value && (
        <UsdValue
          color={errorMsg ? 'primary.error' : 'primary.text2'}
          textStyle={'body3'}
          value={new Fraction(value)}
          price={tokenPrice}
          decimalPlaces={4}
        />
      )}

      {errorMsg && (
        <Text color="primary.error" textStyle={'body3'}>
          {errorMsg}
        </Text>
      )}
      {warningMsg && (
        <Text color="primary.caution" textStyle={'body3'}>
          {warningMsg}
        </Text>
      )}
    </>
  );
};
