import { chartData as _chartData } from '@ui/components/AmplyCharts/APR.stories';
import { tokenAddressMap } from '@ui/components/TokenInput/constants';
import type { UseAllPoolsData } from '@ui/hooks/data';
import {
  useBorrowAPY,
  useBorrowCap,
  useIncentiveAPY,
  useTokenBorrow,
  useTokenPrice,
  useTokenUtilization,
} from '@ui/hooks/data';
import React, { useMemo } from 'react';

import { Information } from './Information';

const chartData = _chartData.map((data) => ({
  date: data.date,
  value: Number(
    parseInt(
      ((Math.random() + (Math.random() > 0.5 ? -10 : 10)) * data.value).toFixed(
        2,
      ),
    ),
  ),
}));

interface BorrowPanelProps {
  tokenData: UseAllPoolsData;
}

export const BorrowPanel = ({ tokenData }: BorrowPanelProps) => {
  const { data: tokenBorrow } = useTokenBorrow(tokenData.variableDebtToken);
  const { data: tokenPrice } = useTokenPrice(tokenData.tokenAddress);
  const { data: borrowAPY } = useBorrowAPY(tokenData.tokenAddress);
  const tokenUtilization = useTokenUtilization(tokenData);
  const isBorrowCap = useBorrowCap(tokenData);
  const { data: incentiveData } = useIncentiveAPY();
  const incentiveBorrowData = useMemo(() => {
    const incentiveTokenIndex = incentiveData?.tokenAddress.findIndex(
      (item) => tokenAddressMap[item.toLowerCase()] === tokenData.symbol,
    );
    return incentiveData?.borrowIncentiveData[incentiveTokenIndex!];
  }, [incentiveData, tokenData]);
  return (
    <Information
      data={{
        symbol: tokenData.symbol,
        tokenAPY: borrowAPY,
        incentiveDetails: incentiveBorrowData,
        chartData,
        tokenAmount: tokenBorrow,
        tokenUSDValue: tokenPrice,
        utilization: tokenUtilization,
        isCapReached: isBorrowCap,
      }}
      type="borrow"
    />
  );
};
