const primary = {
    text1: '#17181A',
    text2: '#D1D1D1',
    white: '#FFFFFF',
    cta: '#98FF7E',
    active_state: '#C4FFB5',
    layer0: '#05121A',
    layer1: '#10202D',
    disabled: '#838588',
    caution: '#FDF3A7',
    error: '#FF7878',
    divider: '#5d604d',
    logo: '#FFFBB5',
    background: '#05121A',
    backgroundAlt: '#0D0D0D',
    drag_bar: '#295875',
    success: '#FDF880',
    icon_bg: 'rgba(5, 18, 26, 0.5)',
    card_bg: 'rgba(5, 18, 26, 0.3)',
    h2_gray: '#3C4245',
    yellow: '#FFF98C',
};
const gradient = {
    detail: 'linear-gradient(239deg, rgba(217, 217, 217, 0.10) 0.93%, rgba(255, 255, 255, 0.00) 45.88%, rgba(255, 255, 255, 0.08) 99.71%)',
    blur: 'linear-gradient(239deg, rgba(217, 217, 217, 0.10) 0.93%, rgba(255, 255, 255, 0.00) 45.88%, rgba(255, 255, 255, 0.08) 99.71%)',
    h2Gradient: 'linear-gradient(90deg, #AAE9F7 0%, #7FF5E7 100%)',
};
export const colors = {
    primary,
    gradient,
};
