import { ExpandDown, Question } from '@amply-app/icons';
import type { IUiIncentiveDataProviderV3 } from '@amply-app/sdk/dist/esm/types/contracts/UiIncentiveDataProviderV3';
import { Box, Flex, Icon, Text, VStack } from '@chakra-ui/react';
import type { APRChartData } from '@ui/components/AmplyCharts/types';
import TokenImage from '@ui/components/Markets/IndividualMarket/TokenImage';
import { Num, Percent } from '@ui/components/Num';
import { UsdValue } from '@ui/components/Num/UsdValue';
import { Info } from '@ui/components/TextBlocks';
import { POST_MVP_FLAG } from '@ui/constants';
import { SECONDS_PER_DAY } from '@ui/hooks/data/constants';
import { Fraction } from 'bi-fraction';
import React, { useState } from 'react';

import InfoTooltip from '../../InfoTooltip';
import { Chart } from './Chart';

interface Props {
  type: 'supply' | 'borrow';
  data: {
    symbol: string;
    chartData: APRChartData;
    tokenAPY?: Fraction;
    incentiveDetails?: IUiIncentiveDataProviderV3.RewardInfoStructOutput[];
    tokenAmount?: Fraction;
    tokenUSDValue?: Fraction;
    utilization?: Fraction;
    isCapReached?: boolean;
  };
}

export const Information = ({ data, type }: Props) => {
  const {
    chartData,
    symbol,
    tokenAPY,
    incentiveDetails,
    tokenAmount,
    tokenUSDValue,
    utilization,
    isCapReached,
  } = data;
  const [isExpanded, setIsExpanded] = useState(false);
  const isSupply = type === 'supply';

  return (
    <Box>
      <Flex direction={'column'} gap={4}>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Text textStyle={'body3'}>{isSupply ? 'Supply' : 'Borrow'} APR</Text>
          {!POST_MVP_FLAG && <Chart data={chartData} />}
          <Box>
            <Flex>
              <Percent value={tokenAPY} textStyle={'body1Bold'} />
              <Icon
                as={ExpandDown}
                color={'primary.cta'}
                w={6}
                h={6}
                cursor={'pointer'}
                transform={isExpanded ? 'rotate(180deg)' : 'rotate(0)'}
                transition={'all 0.15s'}
                onClick={() => setIsExpanded(!isExpanded)}
              />
            </Flex>
          </Box>
        </Flex>
        {isExpanded && (
          <VStack alignItems={'flex-end'}>
            <Percent
              value={tokenAPY}
              textStyle={'body1'}
              justifyContent={'flex-end'}
              mt={-2}
              prefix={<Text>{'Base:'} &nbsp;</Text>}
            />
            {incentiveDetails?.map((details) => {
              return (
                <Num
                  key={details[0]}
                  value={new Fraction(details[2])
                    .shr(Number(details[6]))
                    .mul(SECONDS_PER_DAY)}
                  textStyle={'body1'}
                  prefix={
                    <>
                      <TokenImage w={'16px'} h={'16px'} symbol={details[0]} />
                      <Text> ${details[0]}:</Text>
                    </>
                  }
                  suffix={'/day'}
                />
              );
            })}
          </VStack>
        )}

        <Info
          title={
            <Text textStyle={'body3'}>
              Total {isSupply ? 'Supply' : 'Borrow'}
            </Text>
          }
          content={
            <>
              <Num value={tokenAmount} textStyle={'body1Bold'} />{' '}
              <TokenImage symbol={symbol} w="22px" />
            </>
          }
          usdContent={<UsdValue value={tokenAmount} price={tokenUSDValue} />}
        />

        <Info
          title={
            <>
              <Text textStyle={'body3'}>{'Utilization'}</Text>
              <InfoTooltip label="The percentage of assets lent out to borrowers" />
            </>
          }
          content={<Percent value={utilization} textStyle={'body1Bold'} />}
        />

        {isCapReached ? (
          <Flex justifyContent={'center'} color={'primary.error'} gap={2}>
            <Icon as={Question} color={'primary.error'} w={6} h={6} />
            <Text textStyle={'body3'}>
              {isSupply ? 'Supply' : 'Borrow'} Cap reached
            </Text>
          </Flex>
        ) : null}
      </Flex>
    </Box>
  );
};
