import { Box } from '@chakra-ui/react';
import React from 'react';
import type { BarProps } from 'recharts';
import {
  Bar,
  ComposedChart,
  Legend,
  Line,
  Rectangle,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {
  textAnchor,
  textFill,
  textOffsetX,
  textOffsetY,
  timeTextOffsetY,
} from './constants';
import {
  CustomizedActiveDot,
  CustomizedReferenceDotLabel,
} from './CustomizedChartTexts';
import { dateFormatter, dateFormatterWithPattern } from './date';
import type { APRAndAmountChartProps, ViewBox } from './types';

const CustomizedReferenceLineLabel = (props: {
  viewBox?: ViewBox;
  currentTime?: Date;
}) => {
  const { viewBox, currentTime } = props;

  if (!viewBox || !currentTime) return null;

  return (
    <text
      x={viewBox.x + viewBox.width / 2}
      y={viewBox.y + viewBox.height + timeTextOffsetY}
      fontSize={'12px'}
      fill={textFill}
      textAnchor={textAnchor}
    >
      {dateFormatterWithPattern(currentTime)}
    </text>
  );
};

const CustomizedActiveBar = (
  props: BarProps & { value?: number; date?: Date },
) => {
  const { x, y, value, width, height, fill, date, index } = props;
  if (!x || !y || !width || !height || !value || !date) return;

  const _x = Number(x);
  const _y = Number(y);
  const textX =
    index === 0 ? _x + width / 2 + textOffsetX : _x + width / 2 - textOffsetX;
  const textY = _y - textOffsetY;

  return (
    <>
      <Rectangle width={width} height={height} x={_x} y={_y} fill={fill} />
      <text
        x={textX}
        y={textY}
        fontSize={'16px'}
        fill={textFill}
        textAnchor={textAnchor}
      >
        {value}
      </text>
      <text
        x={_x + width / 2}
        y={_y + height + timeTextOffsetY}
        fontSize={'12px'}
        fill={textFill}
        textAnchor="middle"
      >
        {dateFormatterWithPattern(date)}
      </text>
    </>
  );
};

export const APRAndAmountChart = ({
  chartType = 'supply',
  data,
  ...props
}: APRAndAmountChartProps) => {
  const currentIndex = data.length - 1;
  const currentTime = data[currentIndex].date;
  const currentData = data.find((d) => +d.date === +currentTime);

  return (
    <Box {...props}>
      <ResponsiveContainer width={'100%'} aspect={1.4}>
        <ComposedChart data={data} barGap={0} barSize={4}>
          <XAxis
            dataKey="date"
            tickFormatter={dateFormatter}
            interval="preserveStartEnd"
            hide
          />
          <YAxis
            yAxisId="left"
            type="number"
            dataKey="apr"
            stroke="#8884d8"
            padding={{
              // padding top for not overflow of the label text
              top: 30,
              bottom: 150,
            }}
            hide
          />
          <YAxis
            yAxisId="right"
            type="number"
            dataKey="amount"
            stroke="#82ca9d"
            padding={{ top: 150 }}
            hide
          />
          <Legend
            align="left"
            wrapperStyle={{
              paddingTop: '18px',
              paddingLeft: '16px',
              fontSize: '12px',
            }}
            iconSize={12}
            payload={
              chartType === 'supply'
                ? [
                    {
                      value: 'Supply APY',
                      type: 'circle',
                      color: '#DE6A4C',
                    },
                    {
                      value: 'Total supply',
                      type: 'circle',
                      color: '#1ED6FF',
                    },
                  ]
                : [
                    {
                      value: 'Borrow APY',
                      type: 'circle',
                      color: '#4CDE52',
                    },
                    {
                      value: 'Total borrow',
                      type: 'circle',
                      color: '#1ED6FF',
                    },
                  ]
            }
          />
          <Line
            yAxisId="left"
            type="monotone"
            dot={false}
            dataKey="apr"
            stroke={chartType === 'supply' ? '#DE6A4C' : '#4CDE52'}
            strokeWidth={'4px'}
            r={'8px'}
            activeDot={<CustomizedActiveDot fontSize={'16px'} />}
          />
          <Bar
            yAxisId="right"
            dataKey="amount"
            fill="#1ED6FF"
            activeBar={<CustomizedActiveBar dataKey="amount" />}
          />
          <Tooltip
            content={() => null}
            cursor={{
              strokeOpacity: '0.12',
              stroke: 'white',
            }}
          />
          <ReferenceLine
            yAxisId="left"
            x={+currentTime}
            stroke="rgba(255,255,255, 0.12)"
            label={<CustomizedReferenceLineLabel currentTime={currentTime} />}
          />

          <ReferenceDot
            yAxisId="left"
            x={+currentTime}
            y={currentData?.apr}
            r={8}
            strokeWidth={2}
            stroke="#000"
            fill={chartType === 'supply' ? '#DE6A4C' : '#4CDE52'}
            label={
              <CustomizedReferenceDotLabel
                value={currentData?.apr}
                index={currentIndex}
                fontSize="16px"
              />
            }
          />
          <ReferenceDot
            yAxisId="right"
            x={+currentTime}
            y={currentData?.amount}
            r={0}
            strokeWidth={0}
            label={
              <CustomizedReferenceDotLabel
                value={currentData?.amount}
                index={currentIndex}
                fontSize="16px"
              />
            }
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};
