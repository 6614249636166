export const EXTERNAL_LINK = {
  gitbook: {
    home: 'https://docs.amply.finance/',
    hf: 'https://docs.amply.finance/health-factor-and-loan-to-value-ratio-ltv/health-factor-and-loan-to-value-ratio-ltv',
    audit: 'https://docs.amply.finance/information-to-know/audits',
    riskDisclosure: 'https://docs.amply.finance/information/risk-disclosure',
    tokenomics: 'https://docs.amply.finance/introduction-to-amply/tokenomics',
  },

  poinnerMissons: 'https://zkevm.cronos.org/missions/',

  twitter: 'https://x.com/amplyfinance ',
  discord: 'https://discord.com/invite/Yd49zXmuJN',
  telegram: 'https://t.me/amplyfinance',

  h2Finance: 'https://h2.finance/',
  ebisusbay: 'https://ebisusbay.com/',
  wolfswap: 'https://wolfswap.app/',
  crolonmars: 'https://crolonmars.com/',
  fulcrom: 'https://fulcrom.finance/',
  puush: 'https://www.puush.fun/',

  cronosZkevm: 'https://zkevm.cronos.org/bridge',
  xyFinance: 'https://app.xy.finance/',
  symbiosis: 'TBD',
  deBridgeFinance: 'https://app.debridge.finance/',
} as const;
