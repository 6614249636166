import { Box } from '@chakra-ui/react';
import React from 'react';
import {
  Legend,
  Line,
  LineChart,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { textAnchor, textFill, textOffsetX } from './constants';
import {
  CustomizedActiveDot,
  CustomizedReferenceDotLabel,
} from './CustomizedChartTexts';
import { dateFormatter } from './date';
import type { InterestRateChartProps, ViewBox } from './types';

const timeTextOffsetY = -10;

const CustomizedReferenceLineLabel = (props: {
  viewBox?: ViewBox;
  index?: number;
}) => {
  const { viewBox, index } = props;
  if (!viewBox) return null;
  const textX = index === 0 ? viewBox.x + textOffsetX : viewBox.x - textOffsetX;
  const textY = viewBox.y - timeTextOffsetY;

  return (
    <text
      x={textX}
      y={textY}
      fontSize={'14px'}
      fill={textFill}
      textAnchor={textAnchor}
    >
      Current
    </text>
  );
};

export const InterestRateChart = ({
  data,
  ...props
}: InterestRateChartProps) => {
  const currentIndex = data.length - 1;
  const currentTime = data[currentIndex].date;
  const currentData = data.find((d) => +d.date === +currentTime);

  return (
    <Box {...props}>
      <ResponsiveContainer width={'100%'} aspect={1.52}>
        <LineChart
          data={data}
          margin={{
            right: 20,
            left: 20,
          }}
        >
          <Tooltip
            content={() => null}
            cursor={{
              strokeOpacity: '0.12',
              stroke: 'white',
            }}
          />
          <Legend
            align="left"
            iconType="circle"
            wrapperStyle={{
              paddingTop: '16px',
              paddingLeft: '16px',
              fontSize: '12px',
            }}
          />
          <XAxis
            dataKey="date"
            tickFormatter={dateFormatter}
            tick={{
              fontSize: 10,
              fontWeight: '600',
            }}
            interval="preserveStartEnd"
            hide
          />
          <YAxis hide />
          <Line
            strokeWidth={'4px'}
            dot={false}
            type="monotone"
            dataKey="Utilization"
            stroke="#FFF"
            activeDot={<CustomizedActiveDot />}
          />
          <Line
            strokeWidth={'4px'}
            dot={false}
            type="monotone"
            dataKey="Supply APY"
            stroke="#FF96DF"
            activeDot={<CustomizedActiveDot />}
          />
          <Line
            strokeWidth={'4px'}
            dot={false}
            type="monotone"
            dataKey="Borrow APY"
            stroke="#F4F499"
            activeDot={<CustomizedActiveDot />}
          />

          <ReferenceLine
            x={+currentTime}
            stroke="rgba(255,255,255, 0.12)"
            label={<CustomizedReferenceLineLabel index={currentIndex} />}
          />

          <ReferenceDot
            x={+currentTime}
            y={currentData?.Utilization}
            r={8}
            strokeWidth={2}
            stroke="#000"
            fill="#FFF"
            label={
              <CustomizedReferenceDotLabel
                value={currentData?.Utilization}
                index={currentIndex}
              />
            }
          />
          <ReferenceDot
            x={+currentTime}
            y={currentData?.['Supply APY']}
            r={8}
            strokeWidth={2}
            stroke="#000"
            fill="#FF96DF"
            label={
              <CustomizedReferenceDotLabel
                value={currentData?.['Supply APY']}
                index={currentIndex}
              />
            }
          />
          <ReferenceDot
            x={+currentTime}
            y={currentData?.['Borrow APY']}
            r={8}
            strokeWidth={2}
            stroke="#000"
            fill="#F4F499"
            label={
              <CustomizedReferenceDotLabel
                value={currentData?.['Borrow APY']}
                index={currentIndex}
              />
            }
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};
