import { Stack, Text } from '@chakra-ui/react';
import HealthFactor from '@ui/components/HealthFactor';
import InfoTooltip from '@ui/components/InfoTooltip';
import { Num, Percent } from '@ui/components/Num';
import { PercentageSelect } from '@ui/components/PercentageSelect';
import { Info } from '@ui/components/TextBlocks';
import { TokenInput } from '@ui/components/TokenInput';
import {
  HealthFactorType,
  useHealthFactor,
  useSupplyAPY,
  useTokenBalance,
  useTokenPrice,
} from '@ui/hooks/data';
import {
  AvailableBorrowType,
  useAvailableBorrow,
} from '@ui/hooks/data/useAvailableBorrow';
import useReservedGasFee from '@ui/hooks/data/useReservedGasFee';
import { useValidationSupply } from '@ui/hooks/validation';
import getPositiveOrZeroFraction from '@ui/utils/getPositiveOrZeroFraction';
import { Fraction } from 'bi-fraction';

import { useSupplyAndBorrowModal } from '..';
import { usePaymaster } from '../Paymaster';

export const Supply = () => {
  const [supplyAmount, value, setValue, setSelectedTokenData, tokenData] =
    useSupplyAndBorrowModal((s) => [
      s.supplyAmount,
      s.supplyAmountStr,
      s.setSupplyAmountStr,
      s.setSupplyTokenData,
      s.supplyTokenData,
    ]);
  const { data: balance } = useTokenBalance(tokenData?.tokenAddress);
  const { data: supplyAPY } = useSupplyAPY(tokenData?.tokenAddress);
  const { data: tokenPrice } = useTokenPrice(tokenData?.tokenAddress);
  const gasToken = usePaymaster((s) => s.token);
  const gasFeeMap = useReservedGasFee();
  const maxSupplyValue =
    gasToken !== tokenData?.symbol
      ? balance
      : getPositiveOrZeroFraction(
          balance?.sub(gasFeeMap?.[tokenData.symbol] ?? Fraction.ZERO),
        );
  const newHealthFactor = useHealthFactor(
    HealthFactorType.Supply,
    tokenPrice ? supplyAmount?.mul(tokenPrice) : Fraction.ZERO,
    tokenData?.tokenAddress,
  );
  const availableBorrow = useAvailableBorrow(
    AvailableBorrowType.Supply,
    tokenPrice ? supplyAmount?.mul(tokenPrice) : Fraction.ZERO,
    tokenData?.tokenAddress,
  );
  const errorMsg = useValidationSupply(
    supplyAmount ?? Fraction.ZERO,
    tokenData,
  );
  return (
    <>
      <Stack spacing={3}>
        <TokenInput
          value={value}
          setSelectedTokenData={setSelectedTokenData}
          selectedTokenData={tokenData}
          onChange={setValue}
          errorMsg={errorMsg}
        />
        <PercentageSelect
          value={value}
          maxValue={maxSupplyValue}
          decimalPlaces={4}
          onSelect={setValue}
          isDisabled={!tokenData}
        />
        <Info
          title="Supply APR"
          content={tokenData ? <Percent value={supplyAPY} /> : <Text>-</Text>}
        />
        <Info
          title={
            <>
              Available to Borrow
              <InfoTooltip label="Maximum amount available for you to borrow, assuming that there is no borrow cap" />
            </>
          }
          content={
            <Num value={availableBorrow} textStyle={'body2'} suffix={'USD'} />
          }
        />
        <HealthFactor value={newHealthFactor} />
      </Stack>
    </>
  );
};
