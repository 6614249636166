import { Box } from '@chakra-ui/react';
import type { Meta } from '@storybook/react';

import { APRChart } from '.';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default {
  title: 'Components/APRChart',
  component: APRChart,
} as Meta;

export const chartData = [
  {
    date: new Date(2019, 0, 11),
    value: 20,
  },
  {
    date: new Date(2019, 4, 30),
    value: 40,
  },
  {
    date: new Date(2019, 5, 2),
    value: 60,
  },
  {
    date: new Date(2019, 5, 20),
    value: 65,
  },
  {
    date: new Date(2019, 5, 26),
    value: 25,
  },
  {
    date: new Date(2019, 5, 30),
    value: 30,
  },
  {
    date: new Date(2019, 6, 5),
    value: 90,
  },
];

export const APRChartDefault = () => {
  return (
    <Box bg="black" p={3}>
      <APRChart
        data={chartData}
        chartContainerProps={{ width: '100%', minHeight: 260, aspect: 2 }}
        areaProps={{ stroke: '#79FAFB' }}
        linearGradientProps={{
          stopColor: '#79FAFB',
          stopOpacity: '0.6',
          id: 'apr',
        }}
      />
    </Box>
  );
};
