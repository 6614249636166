import { useQuery } from '@tanstack/react-query';
import { RefetchInterval } from '@ui/config/ui';
import { getSDK } from '@ui/utils';
import { Fraction } from 'bi-fraction';

import { checkZKCROAddress } from './utils';

export const useTokenPrice = (tokenAddress?: string) => {
  const sdk = getSDK();
  return useQuery(
    ['useTokenPrice', tokenAddress],
    async () => {
      const address = checkZKCROAddress(tokenAddress);
      const price = await sdk.getAmplyOracle().getAssetPrice(address!);

      return new Fraction(price).shr(8);
    },
    { enabled: !!tokenAddress, refetchInterval: RefetchInterval.Normal },
  );
};

export const useTokenPrices = (tokenAddresses: string[]) => {
  const sdk = getSDK();
  return useQuery(
    ['useTokenPrices', tokenAddresses],
    async () => {
      const assresses = tokenAddresses.map(
        (addr) => checkZKCROAddress(addr) ?? '',
      );
      const prices = await sdk.getAmplyOracle().getAssetsPrices(assresses);

      return prices.map((price) => new Fraction(price).shr(8));
    },
    { enabled: !!tokenAddresses, refetchInterval: RefetchInterval.Normal },
  );
};
